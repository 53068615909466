@import '~scss/components';
@import '~component-lib/swatches';

.page-panel__stats__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;

  @include screen-size-xs {
    flex-grow: 1;

    &:not(:last-child):after {
      content: "";
      position: absolute;
      top: 0;
      right: 0px;
      display: block;
      height: 100%;
      border-left: 1px solid $divider_gray;
    }
  }

  @include screen-size-sm {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.page-panel__stats__label {
  font-size: 14px;
  color: $label_gray;
  text-align: center;
}

.page-panel__stats__indicator {
  font-size: 24px;
  font-weight: 500;
  color: $stat_number_gray
}
