@import '~scss/components';

.input__textarea {
  border: 1px dashed #B1E5FC;
  background: #FCFDFD;
  padding: 14px 24px;
  position: relative;
  outline: none;
  width: 100%;

  > textarea {
    color: #124F71;
    font-size: 18px;
    font-weight: 300;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    background: none !important;
    border: none !important;
    resize: none;
    padding: 0;
    margin: 0;
    outline: none;

    &::placeholder {
      font-size: 16px;
      font-style: italic;
      color: #6B9FB8;
      font-weight: 200;
    }
  }

  &.is--medium {
    background: #fff;

    > textarea {
      font-size: 16px;
      font-weight: 200;
    }
  }

  &.is--large {
    > textarea {
      line-height: 34px;
      font-size: 24px;
      color: #124F71;
    }
  }

  &.has--lines {
    @include screen-size-xxs() {
      padding: 8px;
      line-height: 31px;
      font-size: 16px;
      border: none;
      background: #FFFFFF;
      background-image:
        linear-gradient(to left, white 10px, transparent 10px),
        linear-gradient(to right, white 10px, transparent 10px),
        linear-gradient(white 30px, #D2D8DA 30px, #D2D8DA 31px, white 31px);
      background-size: 100% 100%, 100% 100%, 100% 31px;

      &:before {display: none;}

      > textarea {
        font-size: 16px;

        &::placeholder {
          font-size: 16px;
          font-style: italic;
          color: #6B9FB8;
          font-weight: 200;
        }
      }
    }
  }

  .has--error & { border-color: #EF5350 }
}
