.text-collapsible {
  font-weight: 200;
  color: #324048;
  font-size: 16px;
  padding-bottom: 24px;
}

.text-collapsible__wrapper {
  overflow: hidden;
  max-height: 150px;
  position: relative;
  transition: max-height .5s ease-in-out;
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #FAFBFC 100%);
    height: 42px;
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }
  .is--expanded & {
    max-height: 100%;

    &:after {
      opacity: 0;
    }
  }
}

.text-collapsible__show-link {
  font-weight: 400;
  position: absolute;
  width: 100%;
  color: #37B1FF;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  text-align: center;
  display: block;
  cursor: pointer;
  transition: all .3s ease-in-out;

  .is--expanded & {
    opacity: 0;
    height: 0;
  }
}
