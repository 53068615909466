.application-challenges {
  padding: 0 10px;
}

.application-challenges__header {
  text-align: center;
}

.application-challenges__title {
  color: #6B9FB8;
  font-size: 18px;
  font-weight: 400;
}

.application-challenges__tagline {
  color: #A6C5D4;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
}
