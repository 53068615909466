@import '~scss/components';

.page-panel__extension {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.page-panel__extension__header {
  font-size: 16px;
  color: #124F71;
  line-height: 0.5;
  text-align: center;
  padding: 24px;
  font-weight: 400;

  span {
    display: inline-block;
    position: relative;
  }

  @include screen-size-sm {
    span:before,
    span:after {
      content: "";
      position: absolute;
      height: 5px;
      border-bottom: 1px solid #DCE7EA;
      top: 0;
      width: 180px;
    }

    span:before {
      right: 100%;
      margin-right: 70px;
    }

    span:after {
      left: 100%;
      margin-left: 70px;
    }
  }
}

.page-panel__extension__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
