.text {
  color: #124F71;
  font-size: 16px;
  font-weight: 300;

  &.is--small {
    color: #6B9FB8;
    font-size: 14px;
  }

  &.is--muted {
    pointer-events: none;
    position: relative;
    z-index: 2;
    text-indent: -9999px;
    color: transparent;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 10%;
      right: 0;
      bottom: 10%;
      background: #EAF4F7;
      z-index: 2;
    }
  }
}


