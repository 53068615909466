@import "~scss/components";

.form__subrow {
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-bottom: -14px;

  .form__row > & {
    flex: 1 auto;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  & > * {
    width: auto;
    flex: 1 auto;
  }

  > .form__field {
    margin-left: 24px;
    margin-bottom: 14px;
  }

  > .form__field__label {
    width: 100%;
    margin-left: 24px;
  }

  > .form__field.is--full {
    position: relative;
    margin: 4px 0 -44px 0;
    z-index: 1;
    width: 100%;
  }
}

@include screen-size-xs() {
  .form__subrow > .form__field {
    width: 50%;
  }

  .form__row > .form__subrow + .form__subrow {
    width: 100%;
    margin-top: 14px;
  }
}
