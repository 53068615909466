.list-panel__thumb {
  font-size: 56px;
  line-height: 56px;
  height: 56px;
  width: 56px;
  min-width: 56px;
  position: relative;
}

.list-panel__thumb__addons {
  .is--sticker & {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
