@import '~scss/components';

.administrators {
  display: flex;
  align-content: center;
}

.administrator {
  position: relative;
  width: 100%;
  color: #124F71;
  background-image: url('./icon--administrator.svg');
  background-repeat: no-repeat;

  @include screen-size-xs() {
    padding: 60px 24px 24px;
    background-position: 50% 0;
  }

  @include screen-size-sm() {
    padding-right: 60px;
    padding-left: 100px;
    background-position: 0 50%;
  }
}

.administrator__name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.administrator__email {
  font-size: 16px;
  font-weight: 200;
}

.administrator__role {
  top: 50%;
  margin-top: -12px;
  line-height: 24px;

  @include screen-size-xs() {
    position: relative;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  @include screen-size-sm() {
    position: absolute;
    right: 24px;
  }
}
