@import '~scss/components';

.form__cards {
  counter-reset: cards-counter;
  padding-bottom: 80px;
  position: relative;

  @include screen-size-sm() {
    padding-left: 40px;

    &:before {
      content: '';
      opacity: .4;
      position: absolute;
      left: 14px;
      top: -10px;
      bottom: -1000px;
      width: 1px;
      border: 1px dashed #D2D8DA;
    }
  }
}
