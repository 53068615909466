@import '~scss/components';

.application-tabs {
  display: flex;
  align-items: center;
  margin-top: -14px;
  margin-bottom: -14px;
  font-size: 16px;
}

.application-tabs__score {
  color: #95A9B3;
  text-align: center;
  font-size: 22px;
  padding: 24px 40px 24px 16px;
  border-right: 1px solid #DCE7EA;
}

.application-tabs__score__title {
  font-size: 16px;
}

.application-tabs__educator {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  color: #4B88A5;

  @include screen-size-xs {
    width: 70%;
    justify-content: center;
    text-align: center;
  }
}

.application-tabs__educator__icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  margin-left: 24px;
  font-size: 40px;
  color: #B1E5FC;

  @include screen-size-xs {
    display: none;
  }
}

.application-tabs__educator__title {
  > div { font-weight: 500; }
}
