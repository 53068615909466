.panel {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.12);
  border-radius: 6px;
  padding: 38px 38px 20px;
  margin-bottom: 24px;
  text-align: center;
  position: relative;
  user-select: none;
  position: relative;

  &.is--welcome {
    background: #37B1FF;
    border: 1px solid #37B1FF;
    color: #fff;
  }

  &.is--coming-soon {
    background: #fff;
    color: #124F71;
  }

  &.is--cta {
    background: #F2EEF8;
    color: #7E57C2;
    padding: 24px;
  }
}

.panel__transition {
  position: relative;
  overflow: hidden;
}

.panel__icon {
  position: absolute;
  left: 5%;
  width: 13%;

  .is--cta & {
    width: 9%;
    margin-top: -4.5%;
    top: 50%;
  }
}

.panel__title {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  margin: -4px 0 0;

  .is--coming-soon & {
    color: #03A9F4;
  }
}

.panel__content {
  width: 64%;
  margin: 0 auto;

  .is--cta & {
    display: flex;
    width: auto;
    padding-left: 16%;
    align-items: center;
  }
}

.panel__text {
  font-size: 14px;
  font-weight: 300;
  margin: 14px 0 20px;

  .is--coming-soon & {
    line-height: 24px;
    font-size: 16px;
  }

  .is--cta & {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    padding: 0 24px 0 0;
    width: 100%;
  }
}

.panel__close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 14px;
  top: 8px;
  cursor: pointer;
  user-select: none;
  font-size: 24px;
  color: #fff;

  &:hover {
    opacity: .6;
  }
}

.panel__vote {
  margin: 34px 0 14px 0;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #95A9B3;
}

.panel__vote__item {
  padding-left: 14px;
  cursor: pointer;

  .icon {
    vertical-align: middle;
  }

  &:first-child {
    margin-left: 10px;
  }
}

.panel__vote__icon {
  font-size: 30px;
  display: inline-block;
  margin: -20px 7px -10px 0;

  .panel__vote__item.is--active &,
  .panel__vote__item:hover & {
    color: #9ADDFB;
  }
}

@media (max-width: 480px) {
  .panel__icon {
    position: relative;
    left: 0;
  }

  .panel__content {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding:0 !important;
  }

  .panel__text {
    padding: 0;
    margin: 14px 0 20px !important;
  }

  .panel__icon {
    top: 0 !important;
    width: 20% !important;
    margin: 0 !important;
  }

  .panel .Button {
    min-width: 0 !important;
    width: 100% !important;
  }
}
