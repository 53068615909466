.list-group__title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  max-height: 0;
  height: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  transition: max-height .2s linear, opacity .2s linear;

  & > span {
    padding-bottom: 8px;
  }

  &.is--visible {
    max-height: 70px;
    opacity: 1;
  }

  .is--success  & { color: #10E7BE; }
  .is--primary  & { color: #69D2F7; }
  .is--warning  & { color: #FEBF0E; }
  .is--danger   & { color: #FF720C; }
  .is--inactive & { color: #124F71; }
}
