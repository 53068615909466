@import '~scss/components';

.job-applications-details {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 120px;

  @include screen-size-xs {
    flex-direction: column;
  }
}
