@import '~scss/mixins/screen-size';

.InfoEnvelope {
  text-align: center;

  > :not(:first-child) {
    margin-top: 16px;

    @include screen-size-sm {
      margin-top: 24px;
    }
  }

  &__img {
    max-width: 160px;
    margin-top: -40px; // Remove extra space on top of the envelope when it's closed

    @include screen-size-sm {
      max-width: 260px;
      margin-top: -64px; // Remove extra space on top of the envelope when it's closed
    }
  }
}
