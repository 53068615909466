.avatar-uploader {}

.avatar-uploader__canvas {
  border: 1px solid #D4DAD9;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  overflow: hidden;
  margin: 44px auto;
  opacity: 1;
  position: relative;
  transition: opacity .2s ease-in-out, background .2s ease-in-out;

  .is--uploading & {
    opacity: .5;
    pointer-events: none;
  }

  .is--empty & {
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.13;
      color: #D4DAD9;
      background: url('~components/svg-icon/svgs/camera.svg') 50% 50% no-repeat;
      background-size: 90px;
    }
    &:hover {
      background: #FAFAFA;
    }
  }

  canvas {
    border-radius: 100%;
  }
}

input.avatar-uploader__file {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 200px !important;
  opacity: 0;
  cursor: pointer;
}

.avatar-uploader__delete {
  position: absolute;
  color: #03A9F4 !important;
  background: #fff;
  z-index: 1;
  font-size: 31px;
  text-align: left;
  line-height: 30px;
  width: 30px;
  height: 30px;
  right: 50%;
  top: 70px;
  margin-right: -70px;
  cursor: pointer;
  border-radius: 100%;

  &:active:before { top: 1px;}
  &:before { position: relative; }
}

.avatar-uploader__range {
  position: relative;
  margin: 68px 17px 44px;
  padding: 0 24px;

  &:before,
  &:after {
    color: #D2D8DA;
    font-size: 44px;
    position: absolute;
    line-height: 30px;
    height: 24px;
    font-weight: 200;
    top: 0;
  }
  &:before {
    content: '-';
    left: 0;
  }
  &:after {
    content: '+';
    right: 0;
  }
}

.avatar-uploader__range__input {
  -webkit-appearance: none;
  outline: none !important;
  border: none !important;
  background: none !important;

  &::-webkit-slider-runnable-track {
    height: 2px;
    background: #EAF4F7 !important;
    border: none;
    border-radius: 0;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #fff;
    margin-top: -12px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0, 0.10);
    transition: box-shadow .1s linear;
  }

  &:active::-webkit-slider-thumb {
    box-shadow:
      0 0 3px rgba(0,0,0, 0.10),
      0 0 0 7px rgba(#69D2F7, .1);
  }
}
