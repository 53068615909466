.form__question {
  padding: 0 7px 24px 24px;
  position: relative;
  background: #fff;
  display: flex;
  user-select: none;

  &.is--dragging {
    border-radius: 4px;
    opacity: .9;
    box-shadow: 0 0 0 2px rgba(225, 245, 254, .5);
  }

  .form__field + .form__field {
    margin-top: 14px;
    max-width: 380px;
  }
}

.form__question__fields {
  width: 86%;
}

.form__question__actions {
  display: flex;
  padding: 44px 0 24px 24px;
}

.form__question__action {
  font-size: 32px;
  padding: 14px 0 0;
  margin-top: 7px;
  text-align: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  font-weight: 300;
  color: #A6C5D4 !important;
  padding: 0 !important;
  vertical-align: middle;
}

.form__question__answer {
  max-width: 400px;
}
