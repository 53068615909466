@import '~scss/components';

.challenge-questions-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 780px;

  @include screen-size-xs() {
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  @include screen-size-sm() {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.challenge-questions-header__icon {
  width: 80px;
  height: 80px;

  @include screen-size-xs() {
    align-items: center;
    margin-bottom: 16px;
  }

  @include screen-size-sm() {
    margin-right: 16px;
  }
}

.challenge-questions-header__wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  @include screen-size-xs() {
    flex-direction: column;
    padding-bottom: 24px;
  }
}

.challenge-questions-header__title {
  display: flex;
  flex-direction: column;
  font-size: 22px;
  color: #124F71;
  font-weight: 400;

  @include screen-size-xs() {
    text-align: center;
  }

  @include screen-size-sm() {
    padding-right: 24px;
  }
}

.challenge-questions-header__subtitle {
  font-size: 16px;
  color: #6B9FB8;
  font-weight: 300;

  @include screen-size-xs() {
    text-align: center;
  }
}
