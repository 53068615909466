@import '~scss/mixins/screen-size';

.modal__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding-right: 16px;
  padding-left: 16px;
  visibility: hidden;
  transition: visibility .5s linear;
  overflow: scroll;

  @media(max-height: 640px) { align-items: flex-start; }

  &.is--open {
    visibility: visible;
  }

  .is--landing & {
    padding-left:  0 !important;
  }
}

.modal__icon {
  margin-bottom: 8px;
  font-size: 88px;
  color: #DCE7EA;
}

.modal__title {
  color: #124F71;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  margin: 0;

  .is--large & { font-size: 32px }
}

.modal__actions {
  max-width: 306px;
  margin: 32px auto 0;

  .button + .button {
    margin-top: 8px;
  }
}

.modal__content {
  position: relative;
  padding: 48px 24px 20px;
  font-size: 18px;
  text-align: center;
  color: #124F71;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.10);

  .is--dark & {
    background: #124f71
  }

  .is--small & {
    @include screen-size-sm {
      width: 300px;
    }
  }

  .is--large & {
    @include screen-size-sm {
      width: 640px;
    }
  }

  @include screen-size-xs {
    width: auto;
    max-width: 460px;
  }

  @include screen-size-sm {
    width: 460px;
  }

  @media(max-height: 640px) {
    width: auto;
  }
}

.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  z-index: 9998;
  background: rgba(166, 197, 212, .6);
}

.modal__close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 24px;
  top: 7px;
  font-size:30px;
  cursor: pointer;
  user-select: none;
  color: #BCCACE;
  z-index: 1;
}

.modal {
  z-index: 9999;
  padding: 0 14px;
  margin-top: 100px;
  position: relative;
}
