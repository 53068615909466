@import '~scss/components';

.job-employer-details {
  display: flex;
  flex-direction: column;
}

.job-employer-details__employer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.job-employer-details__employer__logo {
  position: relative;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 24px 0 0;
  width: 68px;
  height: 68px;
}

.job-employer-details__employer__name {
  color: #124F71;
  font-size: 16px;
  font-weight: 500;

  a {
    color: #124F71;
    text-decoration: none;
  }
}

.job-employer-details__description {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 748px;
  color: #324048;
  font-weight: 200;

  @include center-block-x();

  @include screen-size-xs() {
    min-width: 100%;
    padding: 15px;
  }

  @include screen-size-sm() {
    padding-left: 92px;
  }

  p {
    font-size: 15px !important;

    &:first-child {
      padding: 0;
      margin: 0;
    }
  }
}

.job-employer-details__view-profile {
  color: #95A9B3;
  font-size: 16px;
  font-weight: 300;
  text-decoration: underline;
  text-align: center;
  padding: 15px 0;
}
