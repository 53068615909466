@import '~scss/components';

.page-panel__stats {
  display: flex;
  justify-content: center;
  padding-right: 16px;
  padding-left: 16px;

  @include screen-size-sm {
    flex-direction: column;
  }
}
