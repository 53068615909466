.list-filter {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;

  &.is--disabled {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: not-allowed;
      z-index: 1;
    }
  }
}
