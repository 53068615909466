@import '~scss/components';
@import '~component-lib/swatches';

.page-list-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // background-color: $white;
  // padding: 24px 0;
  // border-bottom: 1px solid $divider_gray;
}

.page-list-nav__content {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
}

.page-list-nav__title {
  font-size: 22px;
  font-weight: 400;
  color: $blue-darker;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include screen-size-xs {
    padding-right: 8px;
    padding-left: 8px;
  }

  @include screen-size-sm {
    padding-right: 80px;
    padding-left: 80px;
  }
}

.page-list-nav__counter {
  font-size: 15px;
  font-weight: 300;
  color: $stat_number_gray;
}

.page-list-nav__arrow {
  font-size: 40px;
  width: 40px;
  min-width: 40px;
  cursor: pointer;
  color: $legacy-grey;
  transition: color .15s linear;
  display: flex;
}

.page-list-nav__arrow:hover {
  color: $stat_number_gray;
}

.is--right {
  transform: rotate(180deg);
}
