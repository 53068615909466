@import '~scss/components';

.form__panel {
  display: flex;
  align-items: flex-start;
  margin-bottom: 64px;

  @include screen-size-xs() {
    flex-wrap: wrap;

    & > * {
      width: 100% !important;
    }
  }

  & > .card {
    margin: 0;
    width: 70%;
  }
}

.form__panel__content {
  margin-top: 14px;
  width: 30%;
  min-width: 170px;
  position: relative;

  @include screen-size-xs() {
    margin-bottom:  40px;
    text-align: center;
  }

  @include screen-size-sm() {
    margin-right: 40px;
  }
}

.form__panel__title {
  font-size: 22px;
  font-weight: 300;
  color: #124F71;
}

.form__panel__description {
  font-size: 15px;
  font-weight: 300;
  color: #324048;

  @include screen-size-xs() {
    max-width: 400px;

    @include center-block-x();
  }
}

.form__panel__icon {
  color: #DCE7EA;
  font-size: 44px;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: -64px;
}
