@import '~scss/mixins/screen-size';

.payment__card {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 8px 0 16px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
  border-radius: 9px;
  background: #fff;
}

.payment {
  margin: 0 auto;
  padding-bottom: 100px;
  max-width: 820px;
}

.payment__item,
.payment__addon,
.payment__summary__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.payment__item {
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.06);

  @include screen-size-xs() {
    padding: 14px 20px;
  }

  @include screen-size-sm() {
    padding: 24px 60px;
  }

  & + .payment__addon {
    margin-top: 24px;
  }
}

.payment__item__title {
  font-size: 20px;
  color: #324048;
  margin: 0;
  padding: 0;
  max-width: 70%;
  min-width: 60%
}

.payment__item__price {
  font-size: 22px;
  font-weight: 500;
  padding-left: 7px;
  color: #124F71;
}

.payment__item__duration {
  color: #34D5A9;
  font-weight: 500;
}

.payment__addon {
  padding-bottom: 14px;

  @include screen-size-xs() {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include screen-size-sm() {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.payment__addon__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  color: rgba(#324048, .7);

  @include screen-size-xs() {
    font-size: 14px;
  }

  @include screen-size-sm() {
    font-size: 18px;
  }
}

.payment__addon__price {
  font-size: 18px;
  color: #324048;
}

.payment__summary {
  margin-top: 24px;
  padding-top: 14px;
  font-size: 16px;
  color: #124F71;
  border-top: 1.5px solid #D2D8DA;

  @include screen-size-xs() {
    margin-right: 20px;
    margin-left: 20px;
  }

  @include screen-size-sm() {
    margin-right: 60px;
    margin-left: 60px;
  }
}

.payment__summary__item {
  padding: 4px 0;
  justify-content: space-between;
}

.payment__summary__item__title {
  text-align: right;
  flex-grow: 1;

  .is--total & {
    font-weight: 600;
    font-size: 18px;
    margin-top: 1px;
  }
}

.payment__summary__item__price {
  white-space: nowrap;
  margin-left: 30px;
  text-align: right;
  min-width: 70px;

  .is--total & {
    font-weight: 600;
    font-size: 22px;
    color: #FF720C;
  }
}

.payment__footer {
  padding: 24px;
}
