.status {
  width: 100%;
  height: 100%;
  position: relative;

  &.is--horizontal {
    display: flex;
    flex-direction: row;
  }

  &.is--success  { color: #34D5A9 }
  &.is--danger   { color: #FF720C }
  &.is--warning  { color: #FEBF0E }
  &.is--primary  { color: #37B1FF }
  &.is--inactive { color: #95A9B3 }
}

.status__inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;

  .is--horizontal & {
    width: 64px;
    min-width: 64px;
    height: 64px;
  }
}

.status__inner__icon {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 60%;
    height: 60%;
  }
}

.status__inner__icon,
.is--compact .status__duration {
  animation-name: slideIn;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.status__duration {
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
  line-height: 1;

  .is--compact & {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    .list-panel & {
      font-size: 10px;
    }

    .status__duration__label > span {
      display: block;
      width: 100%;
      margin-top: -.1em;
      padding-bottom: .05em;
      font-size: 1.75em;
    }
  }

  .is--horizontal & {
    padding: 0 14px 0 14px;
    text-align: left;
    font-size: 17px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.status__duration__title {
  font-size: 15px;
  width: 100%;
  color: #124F71;

  & + .status__duration__label {
    margin-top: -14px;
  }
}

@keyframes slideIn {
  0% {
    opacity: .6;
    // transform: scale(.9);
  }
  100% {
    opacity: 1;
    // transform: scale(1);
  }
}
