@import '~scss/components';

.card {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 3px 0 rgba(0,0,0, .12);
  max-width: 720px;
  margin: 0 auto;
  width: 100%;
  padding: 32px 0;
  transition: opacity .2s linear;

  &.is--centered {
    align-self: center;
    margin: 40px 0 0;
  }

  &.is--loading {
    cursor: progress;
    opacity: .8;
    pointer-events: none;
  }

  &.has--extension {
    padding-bottom: 0;
  }
  &.has--actions {
    padding-bottom: 56px;
  }

  &.has--one-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-right: 90px;
    padding-bottom: 48px;
    padding-left:90px;

    .form__cards & {
      padding-top: 24px;
      padding-right: 90px;
      padding-bottom: 48px;
      padding-left:90px;
    }
  }

  &.has--pattern {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 140px;
      z-index: 0;
      background: url('./bg--card-patern.svg') 50% 0 no-repeat;
    }
  }

  &.is--dragging {
    box-shadow: 0px 1px 10px 0 rgba(0,0,0, .2);
  }

  .form__cards &,
  .cards &,
  &.is--dragging {
    position: relative;
    margin: 80px 0 72px;
  }
  .form__panel & {
    position: relative;
  }
  .page__title + & {
    margin-top: 24px !important;
  }
}

.card__content {
  padding: 0 24px;
  max-width: 490px;
  margin: 0 auto;

  .cards &,
  .form__cards &,
  .is--dragging &,
  .form__panel &  {
    max-width: none;
  }

  .has--one-column & {
    width: 100%;
  }
}

.card__title {
  text-align: center;
  font-size: 24px;
  color: #124F71;
  font-weight: 400;
  padding: 24px 16px 40px;
  margin: 0;
  line-height: 1.1em;

  small {
    display: block;
    padding-top: 14px;
    font-weight: 300;
    font-size: 16px;
    color: #95A9B3;
  }
}

.card__description {
  font-size: 16px;
  text-align: center;
  padding: 8px 0;
  color: #6B9FB8;
  font-weight: 300;
}

.cards .card__title,
.form__cards .card__title,
.is--dragging .card__title {
  position: absolute;
  top: -34px;
  left: 0px;
  height: 50px;
  padding: 0;
  font-size: 18px;
  color: #95A9B3;
  font-weight: 400;

  .is--dragging &:before { content: '-' }

  &:before {
    width: 28px;
    height: 28px;
    border: 1px solid #B1E5FC;
    background: #fff;
    border-radius: 100%;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    color: #69D2F7;
    position: absolute;
    left: -40px;
    top: -2px;
    content: counter(cards-counter);
    counter-increment: cards-counter;
  }
}

.cards .card__title:before { display: none }

.card__msg {
  font-size: 20px;
  font-weight: 300;
  color: #124F71;
  text-align: center;
  max-width: 460px;
  margin: 14px auto 24px;
  position: relative;
}

.card__heroimg {
  display: block;
  margin: 60px auto 35px;
  position: relative;
  max-width: 70%;
}

.card__actions {
  position: absolute;
  top: 0;
  bottom: -14px;
  right: 7px;

  .is--hidden { visibility: hidden; }
}

.card__action--top {
  position: absolute !important;
  right: 0;
  top: 0;
  padding: 0 8px !important;

  .icon {
    color: #C5D1D5 !important;
  }
}

.card__action--bottom {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

.card__action--drag {
  position: absolute;
  right: 7px;
  top: 14px;
  cursor: pointer;
  text-align: center;
  width: 30px;
  color: #A6C5D4 !important;
  font-size: 30px;
}

.card__links {
  display: flex;
  justify-content: center;
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 24px 0 0 ;

  & > li {
    padding: 3px 0;
  }

  &.is--under {
    padding: 24px 0 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  &.is--vertical {
    flex-direction: column;
  }

  .card__links__msg {
    color: #C1C5C7;
  }

}

.card__link {
  color: #C1C5C7;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;

  &.is--highlighted-link {
    color: #37B1FF;
  }

  .is--under & {
    color: #fff;
  }

  &:hover {
    text-decoration: underline;
  }
}

.emailAddress {
  color: #14E2BB;
  font-weight: 400;
}

@include screen-size-xs() {
  .card {
    &.has--one-column {
      padding: 24px 16px !important;
    }
  }
  .card__heroimg {
    margin-top: -10% !important
  }
  .card__title {
    padding: 16px 8px 24px;

    &:before {
      display: none;
    }
  }
}
