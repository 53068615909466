.input__value {
  min-height: 44px;
  line-height: 44px;
  font-size: 18px;
  font-weight: 200;
  padding: 0 0 0 1px;
  display: flex;
  align-items: center;
  color: #124F71;
  overflow: hidden;

  &.is--inline {
    line-height: 26px;
    min-height: 26px;
    font-size: 15px;
    color: #6B9FB8;
    font-weight: 500;
  }
  &.is--textarea {
    font-size: 16px;
    line-height: 24px;
    display: block;

    & > p {
      margin: 0 0 14px;
    }
  }
  &.is--large {
    line-height: 34px;
    font-size: 24px;
    color: #124F71;
  }
}
