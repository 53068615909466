.card__extension {
  margin-top: 32px;
}

.card__extension__body {
  max-height: 0;
  overflow: hidden;
  background: rgba(234,244,247,0.20);
  transition: all 0.1s ease-in-out;
  padding: 0 32px;
  border-bottom:0 #DCE7EA;
  border-top: 1px solid #DCE7EA;

  .is--expanded & {
    border-bottom: 1px solid #DCE7EA;
    padding: 32px;
    max-height: 3000px;
  }
}

.card__extension__button {
  border-radius: 6px;
}

.card__extension__button__label {
  position: relative;
  font-weight: 400;
  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 11px;
    top:30%;
    left: -26px;
    background: url('../../images/icon--arrow-up.svg') no-repeat;
    transform: rotate(180deg);
  }

  .is--expanded & {
    &:before {
      transform: rotate(0deg);
    }
  }
}
