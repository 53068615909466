@import '~scss/components';

.list {
  position: relative;

  @include screen-size-xs() {
    margin-right: -14px;
    margin-left: -14px;
  }
}

.list + .panel.is--cta {
  margin-top: 24px;
}

.list__container {
  position: relative;
}

.list__subtitle {
  position: absolute;
  line-height: 70px;
  font-size:18px;
  text-align: center;
  color: #C1C5C8;
  left: 0;
  right: 0;
  margin: 0;
  text-transform: capitalize;
}

.list__subtitle + .list {
  padding-top: 60px;
}
