@import '~css/v2/abstracts/functions/get-color-functions';
@import '~css/v2/abstracts/functions/get-font-weight-functions';
@import '~scss/components';

%square-cta-button {
  border-radius: 8px;
  border: 2px dotted get-color(profile, blue-paddl);
  // NOTE: background.svg caused webpack breakage.
  // background: get-color(profile, white) url('background.svg') center center no-repeat;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @include screen-size-xs() {
    height: auto;
    background-size: 80% 60px;
  }

  @include screen-size-sm() {
    height: 144px;
  }
}
%square-cta-button__label {
  display: flex;
  justify-content: center;
  align-items: center;

  @include screen-size-xs() {
    height: 80px;
  }

  @include screen-size-sm() {
    height: 100%;
  }
}
%square-cta-button__label-copy {
  font-size: 18px;
  font-weight: get-font-weight(sofia-pro, regular);
  color: get-color(profile, cerulean);
  margin-left: 8px;
}
