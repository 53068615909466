.form__multipage {
  position: relative;
  height: 540px;
}

.form__multipage__page {
  position: absolute;
  width: 100%;
  left:0;
  top: 0;
}

.form__multipage__nav {
  position: absolute;
  top: -60px;
  display: flex;
  width: 100%;
  padding: 24px 0;
  justify-content: center;
}

.form__multipage__nav__item {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #124f71;
  cursor: pointer;
  margin: 0 14px;
  opacity: 0.05;
  transition: opacity .2s ease-in-out;

  &.is--active {
    opacity: .2;
  }
}
