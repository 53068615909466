@import '~scss/mixins/screen-size';
@import '~scss/variables';

.page__content {
  position: relative;
  display: flex;
  flex: 1 auto;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  max-width: 748px;
  padding-right: 14px;
  padding-left: 14px;
  margin-left: auto;
  margin-right: auto;

  @include screen-size-xs() {
    padding-bottom: 60px;
  }

  & > :not(.modal__container) {
    width: 100%;
  }

  &.is--full {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    width: auto;
    align-self: normal;

    @include screen-size-xs() {
      min-width: 100%;
    }

    @include screen-size-sm() {
      min-width: 0;
    }
  }

  .is--landing & {
    width: 100%;

    @include screen-size-xs() {
      padding-top: 120px;
    }
  }

  &.is--wide {
    max-width: 920px;

    @include screen-size-sm() {
      padding-top: 140px - $navbar-height-sm;
    }

    @include screen-size-xs() {
      padding-top: 140px - $navbar-height-xs;
    }
  }

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.2);
    z-index: 19;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
  }

  .page.has--overlay &:before {
    opacity: 1;
    visibility: visible;
  }
}
