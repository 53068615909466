@import '~scss/components';

.job__challenges__add {
  padding-bottom: 40px;
}

.job__challenges {
  border-radius: 8px;

  @include screen-size-xs() {
    min-width: 0;
    margin-top: -20px;
    margin-right: -10px;
    margin-left: -10px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  @include screen-size-sm() {
    margin-top: -24px;
  }
}

.job__challenges__title,
.job__challenges__subtitle  {
  color: #fff;
  text-align: center;
  font-weight: 500;
}

.job__challenges__title {
  font-size: 20px;
  margin: 14px 0 0;
}

.job__challenges__subtitle {
  font-size: 14px;
  margin: 7px 0 24px;
}

.job__challenges__list {
  @include screen-size-sm() {
    margin-right: -70px;
    margin-left: -70px;
  }
}

.job__challenges__transition {
  transition: transform .2s ease-in-out;
  margin: 0 0 10px;

  &:hover {
    transform: translateY(-4px);
  }
}

.job__challenges__item {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  color: #0288D1;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.10);
  cursor: pointer;
  overflow: hidden;

  @include screen-size-xs() {
    padding: 16px 8px;
  }

  @include screen-size-sm() {
    padding: 14px 24px;
  }
}

.job__challenges__item__title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 7px;
  white-space: nowrap;
}

.job__challenges__item__description {
  color: #324048;
  font-size: 15px;
  font-weight: 300;
  max-width: 260px;
}

.job__challenges__item__icon {
  @include screen-size-xs() {
    width: 40px;
    margin-right: 8px;
  }

  @include screen-size-sm() {
    width: 80px;
    margin-right: 40px;
  }
}

.job__challenges__item__type {
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.job__challenges__add__button {
  text-align: center;
  line-height: 125px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #03A9F4;
  border: 1px dashed #B1E5FC;

  > .icon {
    font-size: 38px;
    vertical-align: middle;
    position: relative;
    top: -3px;
    margin-right: 14px;
    color: #B1E5FC !important;
  }
}
