.list-panel__checkbox {
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 38px;
  height: 38px;
  margin: -18px 0 0 -16px;
  position: absolute !important;
  opacity: 0;

  &,
  & + .avatar,
  & + .avatar + .list-panel__thumb__addons,
  & + .status {
    transition: opacity .2s linear;
  }

  .list-panel:hover & + .status { opacity: 0 }
  .list-panel:hover & + .avatar { opacity: 0 }
  .list-panel:hover &           { opacity: 1 }
  &.is--checked                 { opacity: 1 }
  &.is--checked + .status       { opacity: 0 }
  &.is--checked + .avatar       { opacity: 0 }
  &.is--checked + .avatar + .list-panel__thumb__addons       { opacity: 0 }
  .list-panel:hover & + .avatar + .list-panel__thumb__addons { opacity: 0 }
}
