@import '~scss/components';

.input__autocomplete {
  display: flex;
  cursor: pointer;

  @include screen-size-xs() {
    font-size: 16px;
  }

  @include screen-size-sm() {
    font-size: 24px;
  }
}

.input__autocomplete__addon {
  white-space: nowrap;
  line-height: 44px;
  padding-right: 14px;
  color: #0C4E72;
  font-weight: 200;

  @include screen-size-xs() {
    display: none;
  }
}

.Select-control {
  position: relative;
  width: 100%;
  height: 44px;
  min-height: 44px;
  padding-left: 0;
  line-height: 44px;
  font-weight: 300;
  font-size: 24px;
  vertical-align: middle;
  color: #2CB0FF;
  cursor: default;
  outline: none;
  overflow: hidden;

  @include screen-size-xs() {
    font-size: 16px;
    border-bottom: 1px solid #DCE7EA;
  }

  &:before {
    content: "";
    position: absolute;
    right: 4px;
    width: 30px;
    height: 30px;
    color: #DCE7EA;
    background: url('~components/svg-icon/svgs/icon-search.svg') 50% 50% no-repeat;
    background-size: 18px;
    opacity: 0;
    transition: opacity .3s ease-in-out;

    @include screen-size-xs() {
      top: 0;
      font-size: 16px;
    }

    @include screen-size-sm() {
      top: 8px;
    }
  }

  .is-focused & {
    border-bottom: 1px solid #37B1FF;

    &:before { opacity: .2 }
  }
}

.Select {
  position: relative;
  width: 100%;
}

.Select-menu-outer {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
  box-sizing: border-box;
  max-height: 260px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}

.Select-option,
.Select-noresults {
  display: block;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 200;
  color: #124F71;

  @include screen-size-xs() {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
  }

  @include screen-size-sm() {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.Select,
.Select div,
.Select input,
.Select span {
  box-sizing: border-box;
}

.Select-value {
  cursor: pointer;
}

.is-searchable.is-open > .Select-control {
  cursor: text;
}

.Select-placeholder {
  left: 0;
}

.Select--single > .Select-control .Select-value {
  left: 0;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #2CB0FF;
  position: absolute;
  right: 20px;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-placeholder {
  color: #DCE7EA;
}

.Select-input > input {
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  width: 0;
}

.is-open .Select-input > input {
  cursor: text;
  width: 100% !important;
  color: #40C0FF;
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-clear-zone {
  display: none;
}

.Select-arrow-zone {
  display: none;
}

.Select .Select-aria-only {
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Select-menu {
  overflow-y: auto;

  @include screen-size-xs() {
    max-height: 130px;
  }

  @include screen-size-sm() {
    max-height: 248px;
  }
}

.Select-option {
  border-bottom: 1px solid #EAF4F7;
}

.Select-option:last-child {
  border-bottom: none;
}

.Select-option.is-selected {
  background-color: #EEF6FC;
}

.Select-option.is-focused {
  background-color: #EEF6FC;
}

.Select-menu .Select-create-option-placeholder {
  font-weight: 500;
  background-image: url('../../images/icon--add-custom-item.svg');
  background-position: 97% 50%;
  background-repeat: no-repeat;
  background-size: 30px;
}
