@import '~scss/components';

.page-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;

  @include screen-size-xs() {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @include screen-size-sm() {
    padding: 40px 8px;
  }

  &.is--warning  {
    color: #777777;
    background: #FAF8F0;
  }

  &.is--danger   {
    color: #777777;
    background: #FAF0E8;
  }

  &.is--inactive {
    color: #124F71;
    background: #F4F6F8;
  }

  &.is--success  {
    color: #4B88A6;
    background: #F1FBFA;
  }

  &.is--primary  {
    color: #6B9FB8;
    background: #EFF7FC;
  }
}
