@import '~component-lib/swatches';

.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.-fullscreen {
    position: fixed;
    background: #fff;
    left: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    z-index: 99999;
  }

  .page__content & {
    min-height: 340px;
  }

  > img {
    max-width: 80px;
    max-height: 80px;
  }

  .Button & {
    position: absolute;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom :0;
    text-align: center;
    min-height: 0;

    > img {
      width: 30px;
    }
  }

  .Button.-blue &   { background: $legacy-blue; }
  .Button.-orange & { background: $orange; }
}
