@import '~component-lib/swatches';

/*
# Atom: ProfilePhoto
 */

$profilephoto-size: 54px;
$profilephoto-size-medium: 60px;
$profilephoto-size-large: 120px;
$profilephoto-size-radial-progress: 50px;

.profilePhoto {
  display: block;
  position: relative;
  width: $profilephoto-size;
  height: $profilephoto-size;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  overflow: hidden;
  color: $white;
  text-align: center;
  line-height: $profilephoto-size;
  border: 1px solid #dce7ea;

  &.is-text {
    background-color: $legacy-blue;
    border: 0;
    font-size: 1.2em;
    font-weight: bold;
  }

  img {}
  .profilePhoto-img {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  &.radial {
    width: $profilephoto-size-radial-progress;
    height: $profilephoto-size-radial-progress;
    line-height: $profilephoto-size-medium;
  }

  &.small {
    // 40x40, default
  }
  &.medium {
    // 60x60, in timeline headers for example
    width: $profilephoto-size-medium;
    height: $profilephoto-size-medium;
    line-height: $profilephoto-size-medium;
  }
  &.large {
    // 120x120, when featured, in onboarding as an example
    margin-bottom: 30px; // is ONLY being used on photo page currently, will
                         // potentially need to be edited if used elsewhere
    width: $profilephoto-size-large;
    height: $profilephoto-size-large;
    line-height: $profilephoto-size-large;
  }
  &.-in-sidebar {
    margin: 2px 14px 0 0;
    border-radius: 50%;
    border: 2px solid $white;
    flex-shrink: 0;
  }

  // isEditable overlay
  .editable-overlay {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    font-size: $profilephoto-size-medium / 2;
    cursor: pointer;
  }
  &:hover .editable-overlay {
    visibility: visible;
    opacity: 1;
  }
  // Special case for being nested within FileUploadWrapper
  // See <views/molecules/fileuploadwrapper.scss>
  .fileUploadWrapper .hiddenFileInput:hover + & .editable-overlay {
    visibility: visible;
    opacity: 1;
  }

}
