@import '../functions/get-color-functions';
/**
 * button circle:
 * 2. mixin for hover state for button circle
 * 3. mixin for active state for button circle
 */

@mixin button-circle-hover-state($color) {
  &:hover {
    background: $color;
    box-shadow: 0px 0 0 5px rgba(get-color(buttons, grey-dark),0.15);
  }
}

@mixin button-circle-active-state($color) {
  &:active {
    background: $color;
    box-shadow: 0px 0 0 5px rgba(get-color(buttons, grey-dark),0.03);
  }
}

/**
 * Icon in button circle:
 * 2. mixin for default state for icon
 * 3. mixin for hover state for icon
 */


@mixin icon-in-button-default-state($stroke, $fill) {
   .svg-icon {
    stroke: $stroke;
    fill: $fill;
  }
}

@mixin icon-in-button-hover-state($stroke, $fill) {
  &:hover {
    .svg-icon {
      stroke: $stroke;
      fill: $fill;
    }
  }
}
