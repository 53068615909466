@import '~component-lib/swatches';
@import '~component-lib/font-weight-variables';
@import '~scss/mixins/screen-size';
@import '~css/v2/abstracts/mixins/crop-line-height';

.AccessTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > :not(:first-child) {
    margin-top: 32px;

    @include screen-size-sm {
      margin-top: 24px;
    }
  }

  &__heading {
    margin-top: 0; // Reset h1 style
    margin-bottom: 0; // Reset h1 style

    font-size: 32px;
    font-weight: $font-weight-medium;
    color: $charcoal;

    @include crop-line-height(1.4);
  }

  &__subheading {
    font-size: 14px;
    font-weight: $font-weight-regular;
    color: $new-gray;

    @include crop-line-height(1.4);

    @include screen-size-sm {
      font-size: 18px;
    }
  }
}
