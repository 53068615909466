@import '~scss/components';

.filter-tabs--tabs,
.filter-tabs--buttons {
  display: flex;
  margin: 0 0 24px;
}

.filter-tabs--tabs {
  justify-content: center;
  border-bottom: 1px solid #DCE7EA;
  position: relative;

  .page--profiles-item-student &,
  .page--profiles-item-employer & {
    margin: 0 -15%;
  }
}

.filter-tabs__icon {
  margin: 0 10px 0 0;
  line-height: 15px;
  font-size: 30px;

  &:before {
    color: #9ADDFB;
  }

  .is--active &:before {
    color: #fff;
  }
}

.filter-tabs__underline {
  position: absolute;
  height: 3px;
  background: #69D2F7;
  left: 0;
  bottom: -3px;
  transition: transform .2s ease-in-out;
}

.filter-tabs__item {
  text-align: center;
  user-select:none;
  display: block;
  overflow: hidden;

  &:hover {
    text-decoration: none !important;
  }

  &.is--disabled {
    pointer-events: none;
    opacity: .5;
  }

  .filter-tabs--tabs & {
    width: 25%;
    color: #95A9B3;
    font-size: 16px;
    padding: 7px 0;

    &.is--active {
      color: #124F71;
    }
  }

  .filter-tabs--buttons & {
    width: 100%;
    flex: 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.10);
    border-radius: 4px;
    padding: 14px 0;
    color: #37B1FF;
    font-size:15px;
    margin: 0 7px;
    position: relative;
    white-space: nowrap;

    transition:
      box-shadow .1s linear,
      background .15s ease-in-out,
      color      .15s ease-in-out;

    &.is--active {
      font-weight: 500;
      background: #37B1FF;
      color: #fff;
    }

    &:hover {
      box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.05);
    }
  }

  .filter-tabs--buttons.is--multifilter & {
    &.is--active {
      background: #F1F3F5;
      color: #95A9B3;
      font-weight: 300;
      box-shadow: inset 0 2px 2px 0 rgba(0,0,0,0.1) !important;
      border-radius: 6px;
    }
  }

  .filter-tabs--buttons.has--size & {
    font-size: 16px;
    color: #79939F;
  }
}

.filter-tabs__item__size {
  font-size: 30px;
  font-weight: 600;
  line-height: 16px;
  padding-right: 7px;
  height: 20px;

  .is--open-to-closed & { color: #FEBF0E }
  .is--open           & { color: #34D5A9 }
  .is--closed         & { color: #FF720C }
  .is--draft          & { color: #37B1FF }
  .is--archived       & { color: #C1C5C8 }
}

@include screen-size-xs() {
  .filter-tabs--tabs,
  .filter-tabs--buttons {
    flex-flow: row wrap;
    border: none;
  }
  .filter-tabs--tabs .filter-tabs__item {
    width: auto;
    padding: 7px 24px;

    &.is--active {
      border-bottom: 2px solid #69D2F7;
    }

    &.is--disabled { display: none }
  }
  .filter-tabs--buttons .filter-tabs__item {
    width: 100%;
    margin-top: 14px;
  }
  .filter-tabs__underline {
    display: none;
  }
}
