@import '~scss/components';

.page-section__info-items {
  display: flex;

  @include screen-size-sm {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.page-section__info-item {
  display: flex;
  flex: 1 auto;

  @include screen-size-xs {
    font-size: 12px;
  }
}

.page-section__info-item__icon {
  width: 24px;
  margin-right: 8px;
  font-size: 22px;
  color: #69D2F7;

  @include screen-size-xs {
    display: none;
  }
}

.page-section__info-item__title {
  font-size: 14px;
  color: #124F71;
  font-weight: 500;
}

.page-section__info-item__content {

}
