.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page__section & {
    max-width: 560px;
    margin: 0 auto;
  }
}
