.input__select {
  background: #fff;
  height: 44px;
  line-height: 44px;
  border: 1px solid #A6C5D4;
  border-radius: 4px;
  padding: 0 14px;
  outline: none;

  &[disabled] {
    background: #FAFCFC;
    color: #D2D8DA;
  }
  &.is--inline {
    line-height: 26px;
    width: auto;
    height: 28px;
  }
  &:focus       { border-color: #69D2F7 }
  .has--error & { border-color: #EF5350 }
}
