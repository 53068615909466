@import '~scss/components';

.page__actions {
  position: fixed;
  z-index: 12;
  transition: transform .5s ease-in-out;

  &.is--disabled {
    transform: translateY(140px);
  }

  &.is--top {
    margin-left: 200px;
    padding-right: 7px;
    padding-left: 7px;

    @include screen-size-xs() {
      top: 6px;
      right: 0;
    }

    @include screen-size-sm() {
      right: 0;
      top: 11px;
    }
  }

  &.is--bottom {
    background: #fff;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 14px;
    box-shadow: 0 -1px 1px 0 rgba(0,0,0, .08);
  }
}

.page__actions__container {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: flex;

  > .button + .button{
    margin-left: 24px;
  }

  .is--top & {
    justify-content: flex-end;

    > button + button{
      margin-left: 12px;
    }
  }

  .is--bottom & {
    justify-content: space-around;

    > .span,
    > .button {
      min-width: 200px;
    }

    @include screen-size-xs() {
      flex-direction: column-reverse;

      .button {
        width: 100%;

        & + .button {
          margin-left: 0;
        }
      }
    }

    @include screen-size-sm() {
      > button + button{
        margin-left: 12px;
      }
    }
  }
}

.page__actions__group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .button + .button{
    margin-left: 24px;
  }
}
