@import '~scss/components';

.notifications {
  position: fixed;
  z-index: 101;
  min-width: 160px;

  @include screen-size-xs() {
    top: 0;
    right: 0;
    width: 100%;
    opacity: .9;
  }

  @include screen-size-sm() {
    top: 14px;
    right: 24px;
  }
}

.notification {
  padding-right: 16px;
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  box-shadow: inset 0 0 0 1px #14E3BB;
  cursor: pointer;
  overflow: hidden;

  @include screen-size-xs() {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-weight: 300;
    color: #fff;
    background: #14E3BB;
  }

  @include screen-size-sm() {
    line-height: 44px;
    color: #14E3BB;
    border-radius: 8px;
    background: rgba(255, 255, 255, .8);
  }

  & + .notification { margin-top: 14px }
  &.is--failure     {
    color: #EF5350;
    box-shadow: inset 0 0 0 1px #EF5350;
  }
}

.notification__icon {
  font-size: 24px;
  line-height: 30px;
  margin-left: 8px;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}
