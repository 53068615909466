@import '~component-lib/swatches';

.score {
  padding: 8px 12px;
  margin: 0 8px;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: -1px;
  white-space: nowrap;

  &.is--warning   { color: $warning-yellow; }
  &.is--danger    { color: $danger-red; }
  &.is--inactive  { color: $inactive-gray; }
  &.is--success   { color: $success-green; }
  &.is--primary   { color: $primary-blue; }
}

.score__total {
  font-weight: 300;
  font-size: .9em;
  position: relative;
  color: #C1C5C8;
}
