.page__section__link {
  color: #6B9FB8;
  text-align: center;
  font-size: 15px;

  & > a {
    color: #6B9FB8;
  }
  .icon {
    vertical-align: text-top;
    margin-right: 8px;
    font-size: 24px;
  }
}
