@import '~scss/components';
@import '~component-lib/swatches';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  overflow: hidden;
  z-index: 11;
  background-color: $white;
  padding-right: 24px;
  padding-left: 24px;
  box-shadow: 0 2px 3px rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include screen-size-xs {
    width: 100%;
    height: $navbar-height-xs;
  }

  @include screen-size-sm {
    height: $navbar-height-sm;
  }

  &.is--expanded {
    left: 0 !important;
  }

  &.has--back {
    cursor: pointer;
  }

  &.is--active {
    background-color: $legacy-blue;
  }
}

.navbar__back {
  font-size: 35px;
  width: 30px;
  min-width: 30px;
  position: relative;
  top: 2px;
  left: -7px;
  cursor: pointer;
  color: #A6C5D4;
  transition: color .15s ease-in-out, opacity .1s ease-in-out;
  opacity: 0;

  .navbar.has--back & {
    opacity: 1;
  }

  .navbar.has--back:hover & {
    color: #37B1FF;
  }

  .navbar.has--back.is--active & {
    color: $white;
    opacity: 0.7;
  }

  .navbar.has--back.is--active:hover & {
    color: $white;
    opacity: 1;
  }
}

.navbar__toggle {
  display: none;
  z-index: 10;
  width: 40px;
  height: 28px;
  background: url('~images/icon--sidenav.svg') no-repeat;

  .has--logo & {
    display: none
  }

  @include screen-size-xs() {
    display: block;
  }
}

.navbar__wrapper {
  display: flex;
  align-items: center;

  @include screen-size-xs() {
    width: calc(100% - 100px);
  }
}

.navbar__content {
  width: 100%;
}

.navbar__title {
  font-size: 20px;
  color: #124F71;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .is--active & {
    color: $white;
  }
}

.navbar__subtitle {
  font-size: 15px;
  font-weight: 300;
  color: #A6C5D4;
  margin-top: -2px;
  transition: color .15s linear;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translate3d(0,0,0);
  transition: height .2s linear, opacity .1s .3s linear;

  .navbar.has--back & {
    height: 20px;
    opacity: 1;
  }

  .navbar.has--back:hover & {
    color: #37B1FF;
    max-height: 40px;
  }

  .is--active & {
    display: none;
  }
}
