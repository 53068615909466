.empty-view {
  margin: 0 auto;
  max-width: 340px;
  text-align: center;
  min-height: 200px;
  padding: 70px 0;
}

.empty-view__text {
  font-size: 15px;
  margin: 14px 0 24px;
  color: #124F71;
}

.empty-view__icon {
  width: 140px;
  height: 140px;
}
