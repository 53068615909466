.form {
  transition: opacity .2s ease-in-out;

  textarea,
  input,
  select,
  p {
    font-family: 'sofia-pro', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'sans-serif';
    width: 100%;
    color: #124F71;

    .is--submitting & {
      opacity: .6;
      cursor: progress;
    }
  }

  // &.is--submitting {
  //   animation: pulse 1s infinite;
  // }
}

.form__error {
  background-color: #FFEBEE;
  color: #F2514B;
  font-size: 14px;
  font-weight: 300;
  padding: 24px 14px;

  .card & {
    margin: 0 auto;
    border-radius: 4px;
    max-width: 400px;
  }
}

@keyframes pulse {
  0%   { opacity: .9 }
  50%  { opacity: .4 }
  100% { opacity: .9 }
}
