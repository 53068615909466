@import '~css/v2/abstracts/functions/get-button-functions';

/**
 * button circle:
 * 1. placeholder class for button circle
 * get-button-gutter-vertical = 1rem = 16px;
 * get-button-gutter-horizontal = 1rem = 16px;
 */

%button-circle {
  min-width: get-button-gutter-horizontal() * 3;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: get-button-value(circle-button, border-radius);
  transition: box-shadow .15s ease-in-out;
}

/**
 * Icon in button circle:
 * 1. placeholder class for icon
 */

 %circle-button__svg-icon {
   width: 26px;
   height: auto;
 }
