@import '~scss/components';

.form__field {
  position: relative;
  padding: 0;

  .form__row > & {
    flex: 1 auto;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .form__row.is--stacked & {
    width: auto;
    flex: 0;
    padding:0 7px;
    margin-top: 0 !important;
  }

  &.is--disabled {
    cursor: not-allowed;
  }

  &.has--icon {
    display: flex;
    flex-direction: column;
  }
}

.form__field__wrapper {
  .has--icon & {
    display: flex;
    align-items: center;
  }
}

.form__field__icon {
  color: #69D2F7;
  margin-right: 24px;
  font-size: 32px;
  min-width: 32px;
}
.form__field__label {
  font-size: 14px;
  color: #124F71;
  font-weight: 500;
  padding-bottom: 7px;
  text-align: left;
}

.form__field__description {
  font-size: 16px;
  font-style: italic;
  color: #6B9FB8;
  font-weight: 300;
  margin: -3px 0 14px;
}

.form__field__error {
  color: #EF5350;
  padding: 4px 0 0;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  .has--icon & {
    padding-left: 56px;
  }
}

.form___placeholder {
  font-size: 16px;
  line-height: 44px;
  color: #6B9FB8;
}

@include screen-size-xs() {
  .form__panel .form__row {
    & > .form__field {
      padding-bottom: 14px;
      border-bottom: 1px solid #EAF4F7;
    }

    &:last-of-type > .form__field:last-of-type {
      border-color: transparent;
    }
  }

  .form__panel .is--editing .form__row > .form__field{
    border-color: transparent;
  }

  .form__row > .form__field + .form__field  {
    width: 100%;
    margin-top: 14px;
  }

  .input__text {
    &.is--short {
      width: 100%;
    }
  }

  .form__field__error {
    margin-top: 7px !important;
  }
}
