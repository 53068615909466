.ineligible-modal {
  .modal__actions {
    max-width: 306px;
    margin: 32px auto 0;

    .button {
      margin: 16px 0;
    }
  }

  .modal__help {
    font-size: 16px;
    font-weight: 600;
    color: #4B88A6;
  }
}
