@import '~scss/components';

.job-challenges__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0;
  color: #6B9FB8;
  list-style: none;
  padding-left: 0;

  @include screen-size-xs {
    width: 300px;
    margin-top: 0;

    @include center-block-x();
  }

  @include screen-size-sm {
    margin-top: 20px;
  }
}

.job-challenges__list__item {
  position: relative;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 12px;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  text-align: center;

  @include screen-size-xs {
    margin-top: 10px;
    margin-bottom: -10px;

    &:nth-child(2n):after{
      display: none;
    }
  }

  &:not(:last-child):after {
    position: absolute;
    content: '';
    right: -10%;
    top: 0%;
    width: 30%;
    height: 40%;
    border-bottom: 2px dotted #6B9FB8;
    opacity: 0.5;
  }
}

.job-challenges__list__icon {
  width: 55px;
  height: auto;
}
