.share {
  margin: 0 -24px;
  padding: 0 32px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share__title {
  color: #124F71;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.share__subtitle {
  color: #124F71;
  text-align: center;
  line-height: 1.5em;
  padding: 0 24px;
  margin-bottom: 24px;
}

.share__button {
  height: 56px;
  width: 56px;
  font-size: 54px;
  margin: 0 8px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
.share__wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 24px;
}

.share__link {
  font-weight: 400;
  color: #37B1FF;
  font-size: 16px;
  text-align: center;
  display: block;
  padding: 16px 0;
  cursor: pointer;
}

.form__field__wrapper {
  .has--icon & {
    display: flex;
    align-items: center;
  }
}
