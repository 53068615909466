@import '~scss/components';

.form__row {
  display: flex;
  align-items: flex-start;

  @include screen-size-xs() {
    flex-wrap: wrap;
  }

  & + .form__row {
    margin-top: 34px;
  }

  &.is--centered {
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    * {
      justify-content: center;
      text-align: center;
    }
  }

  &.is--stacked {
    margin: 14px 0 14px;
    font-size: 14px;
    color: #6B9FB8;
    line-height: 26px;
  }

  &.is--spacer {
    margin-top: 40px;
  }

  .form__panel & {
    @include screen-size-sm() {
      margin-bottom: -14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #EAF4F7;
    }
  }

  .form__panel .is--editing &,
  &:last-of-type {
    @include screen-size-sm() {
      border-bottom-color: transparent;
    }
  }
}

.form__row__icon {
  font-size: 30px;
  color: #A6C5D4 !important;
  margin: 0 14px 7px 0;
  width: 30px !important;
  text-align: center;
  align-self: flex-end;

  @include screen-size-xs() {
    display: none;
  }
}
