@import '~scss/components';

.page-panel__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: fadeIn .3s ease-in-out;

  @include screen-size-xs() {
    padding-right: 12px;
    padding-left: 12px;
  }

  @include screen-size-sm() {
    padding-right: 24px;
    padding-left: 24px;
  }

  .is--large & {
    flex-direction: column;
    align-items: center;
  }
}

.page-panel__thumb {
  @include screen-size-xs() {
    width: 72px;
    min-width: 72px;
    height: 72px;
    font-size: 72px;
    text-align: left;
  }

  @include screen-size-sm() {
    width: 80px;
    min-width: 80px;
    height: 80px;
    font-size: 80px;
    text-align: center;
  }

  .is--large & {
    @include center-block-x();

    @include screen-size-xs() {
      font-size: 52px;
      text-align: center;
    }

    @include screen-size-sm() {
      font-size: 80px;
    }
  }

  .is--warning  & { color: #FEBF0E; }
  .is--danger   & { color: #FF720C; }
  .is--primary  & { color: #69D2F7; }
  .is--inactive & { color: #DCE7EA; }
  .is--success  & { color: #10E7BE; }
}

.page-panel__wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @include screen-size-xs() {
    width: 100%;
  }
}

.page-panel__title {
  font-size: 24px;
  font-weight: 400;

  @include screen-size-xs() {
    font-size: 20px;
  }

  .is--warning  & { color: #FEBF0E; }
  .is--danger   & { color: #FF720C; }
  .is--primary  & { color: #69D2F7; }
  .is--inactive & { color: #124F71; }
  .is--success  & { color: #10E7BE; }

  .is--large & {
    text-align: center;
    padding-bottom: 24px;
  }
}

.page-panel__subtitle {
  .is--large & {
    text-align: center;
    max-width: 448px;
    margin: 0 auto;
  }
}

@keyframes fadeIn {
  0%   { transform: translateY(10px); opacity: 0; }
  100% { transform: translateY(0);    opacity: 1; }
}
