.input__radio {
  display: flex;

  & > * {
    width: 100%;
  }
}

.input__radio__item {
  & + .input__radio__item {
    margin-left: 24px;
  }
}

.input__radio__input {
  visibility: hidden;
  position: absolute;
}

.input__radio__label {
  color: #0288D1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: block;
  width: 100%;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.12);
  border-radius: 2px;
  // transition: background .2s ease-in-out, color.2s ease-in-out;

  .input__radio__input:checked + & {
    background: #03A9F4;
    color: #fff;
  }
}
