@import '~scss/components';
@import '~component-lib/swatches';
/*
# Organism: MessageThread and QuestionThread

Include inside main app.scss (extends atoms)
 */

.text-muted {
  color: #c1c5c7;
}

.message .content,
.message .content p {
  font-style: normal;
}

.messageThread {
  padding: 0;
  list-style: none;

  & + .messageThread {
    border-top: 1px solid $grey-light;
    padding-top: 14px;
  }

  .message {
    display: flex;
    margin: 14px 0;

    transition: opacity 0.5s ease-in-out;

    &.messageThread--faded {
      opacity: 0.6;
    }
  }

  &.-questions {
    .message {
      margin: 24px 0;

      .content {
        width: 100%;
      }
    }
  }

  .messageThread--photo {
    width: 70px;
    min-width: 70px;
    padding-top: 14px;
    padding-right: 20px;

    @include screen-size-xs() {
      text-align: center;
    }

    @include screen-size-sm() {
      text-align: right;
    }
  }

  .content {
    // @include span(11);
  }

  .author {
    font-size: 1em;

    .Link:hover {
      text-decoration: underline;
    }
  }
  .content {

  }

  /* for questions */
  .answers {
    padding: 0;
    margin: 0;
    display: flex;

    li {
      width: 100%;
      flex: 1 auto;
      list-style: none;

      & + li {
        margin-left: 24px;
      }
    }

    button {
      width: 100%;
    }
  }
}

.message.is--tuki {
  .author {
    color: #34d5a9;
    font-weight: 400
  }

  .content > div {
    color: #124f71;
    font-weight: 300;
    font-style: italic;
  }
}

.message .author .Link {
  color: $legacy-blue;
  margin-right: 7px;
}


.messageThread:last-child {
  padding-bottom: 14px;
}
