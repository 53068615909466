@import '~scss/components';
@import '~component-lib/swatches';

.page {
  transition: background .5s ease-in-out;
  overflow: hidden;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.is--landing {
    @include screen-size-xs() {
      padding-top: 0 !important;

      // TODO: Refactor to remove hardcoded bottom value
      // In order to align the bottom of the bg image with the top of the footer
      // the bottom value has to be exactly the same as the height of the footer
      &:before,
      &:after {
        bottom: 500.5px; // Total height of Support & PageFooter in mobile view
      }
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    bottom: 0;
    background: #fafbfc;
    transition: background 1s ease-in-out;
  }

  &:before {
    left: 0;
    right: 0;
  }

  &:after {
    width: 300%;
    left: -100%;
    opacity: .25;
    background-repeat: repeat-x;
    background-position: 0 100%;
    animation: zoomIn 1s;
    transform-origin: center bottom;
    background-size: auto 140px;
    transition: transform .7s ease-in-out, opacity .7s ease-in-out;
  }
}

.page__error {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  padding: 14px 0;
  text-align: center;
  background: $red;
  color: $white;
}

.page__map {
  height: 200px;
  background-color: $blue-paddl;

  & > div {
    position: absolute;
    left: 0;
    right: 0;
    height: 200px !important;
  }
}

.page__subtitle {
  @include screen-size-xs() {
    max-width: 80%;
  }
}

.page--applications-item-student {
  background: #fff;
}

.page--register-employer,
.page--register-employer-success {
  // TODO: Refactor to remove hardcoded bottom value
  // In order to align the bottom of the bg image with the top of the footer
  // the bottom value has to be exactly the same as the height of the footer
  &,
  &-student,
  &-employer {
    &:before {
      bottom: 348.5px; // Total height of Support & PageFooter in desktop view
      background-image: linear-gradient(-180deg, #7E57C2 0%, #03A9F4 100%) ;
    }
  }
}

.page--welcome,
.page--welcome-step,
.page--welcome-step-item,
.page--register-student,
.page--register-student,
.page--register-student-step,
.page--register-student-step-item,
.page--register-student-waitlist,
.page--register-student-success {
  &,
  &-student,
  &-employer {
    &:before { background-image: linear-gradient(0, rgba(255,255,255,0.00) 0%, #B1E5FC 100%) }
    &:after  { background-image: url('./bg--login.svg') }

    // TODO: Refactor to remove hardcoded bottom value
    // In order to align the bottom of the bg image with the top of the footer
    // the bottom value has to be exactly the same as the height of the footer
    &:before,
    &:after {
      bottom: 348.5px; // Total height of Support & PageFooter in desktop view
    }
  }

  &.is--step-1:after { transform: translateX(-5%) }
  &.is--step-2:after { transform: translateX(-10%) }
  &.is--step-3:after { transform: translateX(-15%) }
  &.is--step-4:after { transform: translateX(-20%) }
  &.is--step-5:after { transform: translateX(-25%) }
}

.page--register-student-success,
.page--register-student-waitlist {
    &,
    &-student,
    &-employer {
      &:after { opacity: 0 !important }

      // TODO: Refactor to remove hardcoded bottom value
      // In order to align the bottom of the bg image with the top of the footer
      // the bottom value has to be exactly the same as the height of the footer
      &:before,
      &:after {
        bottom: 348.5px; // Total height of Support & PageFooter in desktop view
      }
    }
}

.page--login,
.page--resetpassword,
.page--resetpassword-success,
.page--forgot-password-success,
.page--forgot-password {
  &,
  &-student,
  &-employer {
    &:before { background-image: linear-gradient(-180deg, #82ADDB 0%, #EBB2B1 100%) }
    &:after  { background-image: url('./bg--login.svg') }

    // TODO: Refactor to remove hardcoded bottom value
    // In order to align the bottom of the bg image with the top of the footer
    // the bottom value has to be exactly the same as the height of the footer
    &:before,
    &:after {
      bottom: 348.5px; // Total height of Support & PageFooter in desktop view
    }
  }
}

@keyframes zoomIn {
  0%   { transform: scale(1.2) }
  100% { transform: scale(1)   }
}
