@import '~scss/components';

.list-filter__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #79939F;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none !important;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.10);
  transition:
    box-shadow .1s linear,
    background .15s ease-in-out,
    color      .15s ease-in-out;

  @include screen-size-xs() {
    flex-direction: column;
    margin-right: 4px;
    margin-left: 4px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  @include screen-size-sm() {
    margin-right: 8px;
    margin-left: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.is--active {
    background: #F1F3F5;
    color: #95A9B3;
    box-shadow: inset 0 2px 2px 0 rgba(0,0,0,0.1) !important;
  }

  &:hover {
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.05);
  }
}

.list-filter__size {
  height: 20px;
  line-height: 16px;
  font-size: 30px;
  font-weight: 600;

  @include status-colors-parent();

  @include screen-size-xs() {
    margin-bottom: 4px;
  }

  @include screen-size-sm() {
    padding-right: 7px;
  }
}
