.page__title {
  font-size: 24px;
  margin: 40px 0 24px 0;
  color: #124f71;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;

  & + .page__subtitle {
    margin-top: -14px;
  }

  .card + & {
    margin-top: -40px;
  }
}

.page__subtitle {
  font-size: 15px;
  margin: 0 auto 24px;
  text-align: center;
  color: #95A9B3;

  strong {
    font-weight: 500;
  }

  & + .card {
    margin-top: 32px !important;
  }
}

.page__title + h4 {
  text-align: center;
  margin-bottom: 32px;
}

.page__error + .page__title {
  padding-top: 60px;
}
