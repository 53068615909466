@import '~scss/components';

.job__questions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 120px;

  @include screen-size-xs() {
    background-position: 50% 20px !important;
    padding: 140px 24px 24px;
  }

  @include screen-size-sm() {
    padding-right: 24px;
    padding-left: 180px;
  }

  &.is--questions-insight {
    background: url('~images/icon--questions-insight.svg') 32px 0 no-repeat;
    background-size: 120px;
  }
}

.job__questions__item {
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  & + .job__questions__item {
    margin-top: 14px;
  }
}

.job__questions__item__title {
  color: #124F71;
  font-size: 14px;
  font-weight: 500;
  margin: 0 20px 0 0;
  text-align: right;
  white-space: nowrap;
  min-width: 24px;
  line-height: 22px;
}

.job__questions__item__value {
  color: #124F71;
  font-size: 16px;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
}
