@import '~scss/components';

.job-map {
  position: relative;
  display: flex;
  background-color: #A3CCFF;

  @include screen-size-xs() {
    flex-direction: column-reverse;
  }
}

.job-map__location {
  z-index: 1;
  display: flex;
  min-width: 250px;
  padding: 50px 24px;
  color: #EAF4F7;
  font-weight: 500;
  background-color: #124F71;

  @include screen-size-xs() {
    width: 100%;
    padding: 32px 14px 14px;
    align-items: flex-start;
  }

  @include screen-size-sm() {
    flex-direction: column;
  }
}

.job-map__location__content {
  display: flex;
  flex-direction: column;

  @include screen-size-xs() {
    width: 50%;
  }
}

.job-map__location__title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 13px;
}

.job-map__location__address {
  color: #B1E5FC;
  padding-bottom: 24px;
  line-height: 1.25;
  font-size: 16px;
}

.job-map__location__distance {
  display: flex;
  align-items: flex-start;

  @include screen-size-xs() {
    width: 50%;
    height: 100%;
    padding-top: 34px;
  }
}

.job-map__location__icon {
  color: #B1E5FC;
  font-size: 18px;
  position: absolute;
  left: 0;
}

.job-map__location__label {
  color: #EAF4F7;
  position: relative;
  padding-left: 32px;
}

.job-map__background {
  color: white;
  background-color: gray;

  @include screen-size-xs() {
    position: relative;
    height: 232px;
  }

  @include screen-size-sm() {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
