@import '~scss/components';

.profile-info {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 40px;

  @include screen-size-xs {
    flex-direction: column;
    justify-content: center;
  }

  &.has--content {
    @include screen-size-xs {
      align-items: center;
    }

    @include screen-size-sm {
      align-items: flex-start;
    }
  }
}

.profile-info__avatar {
  position: relative;
  vertical-align: middle;
  transition: height .1s ease-in, width .1s ease-in;

  @include screen-size-xs {
    width: 90px;
    min-width: 90px;
    height: 90px;
    margin-top: -32px;
    margin-bottom: 24px;
  }

  @include screen-size-sm {
    width: 132px;
    min-width: 132px;
    height: 132px;
    margin-right: 32px;
  }
}

.profile-info__title {
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1.3;
  font-style: normal;
  color: #124F71;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include screen-size-xs {
    font-size: 24px;
    text-align: center;
  }

  @include screen-size-sm {
    font-size: 28px;
  }

  &.is--link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.profile-info__subtitle {
  font-size: 20px;
  font-style: normal;
  color: #6B9FB8;
  margin: 0 0 8px 0;
}

.profile-info__info-items {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 0;

  @include screen-size-xs {
    justify-content: center;
    align-items: center;
  }
}

.profile-info__content {
  padding: 0;

  @include screen-size-xs {
    text-align: center;
  }
}

.profile-info__wrapper {
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: #124F71;

  @include screen-size-xs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
