.progress-circle {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;

  .status__inner & {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.progress-circle__path,
.progress-circle__inner {
  transform: translateZ(0);
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  animation-name: arcIn;
  animation-duration: .6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes arcIn {
  0% {
    opacity: 0;
    stroke-dashoffset: -100;
  }
  100% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}
