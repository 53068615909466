@import '~scss/components';
@import '~component-lib/swatches';

.support {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: $white;
  background: $plum-paddl;

  @include screen-size-xs() {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @include screen-size-sm() {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.support__container {
  display: flex;
  max-width: 680px;
  width: 100%;

  @include screen-size-xs() {
    flex-direction: column;
  }

  @include screen-size-sm() {
    align-items: flex-end;

    > :not(:first-child) {
      margin-left: 32px;
    }
  }
}

.support__artwork {
  @include screen-size-xs() {
    > :first-child {
      height: 50px;
    }
  }
}

.support__text {
  > :not(:first-child) {
    margin-top: 20px;
  }

  @include screen-size-xs() {
    padding-top: 10px;

    > :not(:first-child) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @include screen-size-sm() {
    flex-grow: 1;
  }
}

.support__title {
  font-size: 22px;
  font-weight: 400;
}

.support__buttons {
  display: flex;

  @include screen-size-xs() {
    flex-direction: column;
    align-items: center;

    > :not(:first-child) { margin-top: 20px; }
  }

  @include screen-size-sm() {
    > :not(:first-child) { margin-left: 16px; }
  }

  > * {
    flex: 1 auto;
  }
}

.support__button  {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  color: $white;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid $white;
  cursor: pointer;
  transition: background .2s linear;

  > :not(:first-child) {
    margin-left: 8px;
  }

  &:hover,
  &:focus {
    background: $white;
    text-decoration: none;
    color: $plum-electric;
  }

  @include screen-size-xs() {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @include screen-size-sm() {
    padding: 12px 24px;
  }
}

.support__button-icon {
  > svg {
    vertical-align: middle;
  }
}
