@import '~scss/components';

.application-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.application-profile__wrapper {
  display: flex;
  align-items: center;
}

.application-profile__avatar {
  flex-shrink: 0;

  @include screen-size-xs() {
    flex-basis: 72px;
    width: 72px;
    height: 72px;
  }

  @include screen-size-sm() {
    flex-basis: 76px;
    width: 76px;
    height: 76px;
  }
}

.application-profile__info {
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 24px;
}

.application-profile__name {
  font-size: 18px;
  color: #124F71;
  font-weight: 400;
}

.application-profile__last-update {
  font-size: 16px;
  color: #6B9FB8;
  font-weight: 300;
}

.application-profile__label {
  @include screen-size-xs() {
    display: none;
  }
}
