@import '~scss/mixins/screen-size';

.page__header {
  height: 210px;
  margin-bottom: 24px;
}

.page__header__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 208px;
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: 50% 0;
  opacity: .2;

  @include screen-size-xs() {
    bottom: 0;
  }
}

.page__header__content {
  position: relative;
  height: 100%;

  @include screen-size-sm() {
    padding-top: 40px;
  }
}

.page__header__badge {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  justify-content: center;
}

.page__header__badge__container {
  background: #fff;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 1px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.08);
}
