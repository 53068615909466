@import '~scss/mixins/screen-size';
@import '~scss/mixins/page-config';
@import '~scss/variables';

.v2-basecamp-footer {
  @include page-config-x($basecamp-page-content-panel-min-gutter);

  display: flex;
  align-items: center;

  &__last-collected-section {
    display: flex;
    justify-content: space-between;

    > :not(:first-child) { margin-left: 4px; }
  }

  &__collect-xp {
    width: 240px;

    > button {
      width: 100%;
    }
  }

  @include basecamp-page-mobile-view() {
    justify-content: center;

    &__last-collected-section {
      display: none;
    }
  }

  @include basecamp-page-compact-desktop-view() {
    justify-content: space-between;
  }

  @include basecamp-page-loose-desktop-view() {
    justify-content: space-between;
  }
}
