@import '~scss/components';

.list-panel {
  position: relative;
  align-items: center;
  display: flex;
  min-height: 88px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: linear-gradient(
    to right,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,1) 10%,
    rgba(255,255,255,1) 90%,
    rgba(255,255,255,0) 100%
  );

  @include screen-size-xs() {
    padding-right: 8px;
    padding-left: 8px;
  }

  @include screen-size-sm() {
    padding-right: 32px;
    padding-left: 32px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      to right,
      rgba(210, 216, 218, 0) 0%,
      rgba(210, 216, 218, .35) 6%,
      rgba(210, 216, 218, .35) 94%,
      rgba(210, 216, 218, 0) 100%
    );
  }
  &:before { top:0 }
  &:after  { bottom:0 }

  &.has--link {
    cursor: pointer;
  }

  &.has--link:hover {
    background: linear-gradient(
      to right,
      #fafbfc 0%,
      #f4fafe 6%,
      #f4fafe 94%,
      #fafbfc 100%
    );
  }

  & + .list-panel:before { display: none }
}

.list-panel__content {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .list-panel__thumb + & {
    @include screen-size-xs() {
      padding-left: 16px;
    }

    @include screen-size-sm() {
      padding-left: 32px;
    }
  }
}

.list-panel__content__inner {
  width: 100%;
  overflow: hidden;
}

.list-panel__thumb {
  @include screen-size-xs() {
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
  }
}

.list-panel__title {
  font-weight: 300;
  color: #324048;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include screen-size-xs() {
    font-size: 16px;
    line-height: 20px;
  }

  @include screen-size-sm() {
    font-size: 20px;
    line-height: 26px;
  }
}

.list-panel__data {
  color: #95A9B3;
  padding-top: 4px;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .icon {
    vertical-align: baseline;
    margin: 0px 6px -3px 6px;
  }
}

.list-panel__addons,
.list-panel__icon {
  font-size: 24px;
  line-height: 24px;
  max-height: 24px;
}

.list-panel__icon {
  min-width: 24px;
  transition: transform .2s ease-in-out;

  .list-panel:hover & {
    transform: translateX(5px);
  }
}

.job-list-panel__category:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 1;
  vertical-align: middle;
  background-color: #6b9fb8;
  border-radius: 6px;
  margin: auto 8px;
}
