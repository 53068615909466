.summary-user-details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
}
.summary-user-details__avatar {
  height: 44px;
  width:  44px;
  margin-right: 24px;
  margin-top: -4px;
}
.summary-user-details__wrapper {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 300;
  color: #4B88A6;
}
.summary-user-details__name {
  padding-bottom: 8px;
  line-height: 20px;
}
