@import '~scss/mixins/screen-size';
@import '~component-lib/swatches';

.page__section {
  width: 100%;
  margin: 24px 0;
  background-position: 50% 0!important;
  background-size: auto 100%!important;
  background-repeat: repeat-x!important;
  position: relative;

  .page__content > &:first-child {
    margin-top: 0;
  }

  .page-panel + & {
    margin-top: 0;
  }

  // &:last-of-type {
  //   margin-bottom: 0;
  // }

  &.is--container {
    & > * {
      max-width: 748px;
      margin-left: auto;
      margin-right: auto;

      @include screen-size-xs() {
        padding-right: 12px;
        padding-left: 12px;
      }

      @include screen-size-sm() {
        padding-right: 24px;
        padding-left: 24px;
      }
    }

    &.is--narrow > * {
      max-width: 500px;
    }
  }

  &.is--cta {
    background: #fff;
    border-top: 1px solid #EAF4F7;
    border-bottom: 1px solid #EAF4F7;
    padding: 14px 0;
  }

  &.is--primary {
    background: #F2FCFF;
    color: #4B88A5;
    padding: 24px 0;
  }

  &.is--dark {
    padding: 24px 0;
    background: #F3F7F9;
    border-top: 1px solid #DCE7EA;
    border-bottom: 1px solid #DCE7EA;
  }

  &.is--stacked {
    margin-top: -24px;
  }

  &.is--cta + .page__section.is--dark {
    margin-top: -25px;
  }

  &.has--shadow {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
  }

  &.is--centered {
    text-align: center;
  }

}

.page__section.is--disabled:before {
  content:"";
  max-width: 100%!important;
  position: absolute;
  display: block;
  opacity: 0.8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  z-index: 10;
  background: $white;
  margin: 0;
  padding: 0;
  user-select: none;
}

.page__section__header {
  margin-bottom: 14px;
}

.page__section__title {
  font-size: 24px;
  color: #124F71;
  text-align: center;
  font-weight: 400;
  margin-bottom: 10px
}

.page__section__subtitle {
  opacity: 0.9;
  font-size: 16px;
  color: #124F71;
  font-weight: 300;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}
