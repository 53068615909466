.form__questions {
  min-height: 100px;
}

.form__questions__title {
  color: #124F71;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 24px;
  text-align: center;
  text-transform: capitalize;
}

.form__questions__add {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  margin: 40px 0 0;
  cursor: pointer;
  color: #37B1FF;

  & > span {
    user-select: none;
  }
}
