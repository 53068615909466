@import '~component-lib/swatches';

@mixin status-colors-parent() {
  .is--warning  & { color: $warning-yellow; }
  .is--danger   & { color: $danger-red; }
  .is--primary  & { color: $primary-blue; }
  .is--inactive & { color: $inactive-gray; }
  .is--success  & { color: $success-green; }
}

@mixin status-colors() {
  &.is--warning  { color: $warning-yellow; }
  &.is--danger   { color: $danger-red; }
  &.is--primary  { color: $primary-blue; }
  &.is--inactive { color: $inactive-gray; }
  &.is--success  { color: $success-green; }
}
