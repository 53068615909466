.job-status-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-status-summary__status {
  width: 100%;
  height: 64px;

  .status__inner {
    height: 64px;
    width: 64px;
    min-width: 64px;
  }
}
