@import '~scss/components';
@import '~component-lib/swatches';

.job-applications-details__header {
  display: flex;
  align-items: center;
  justify-content: center;

  @include screen-size-xs {
    margin-bottom: 24px;
  }
}

.job-applications-details__thumb {
  width: 80px;
  min-width: 80px;
  height: 80px;
  @include status-colors-parent();
}

.job-applications-details__wrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: relative;
  align-items: flex-start;
  padding: 0 24px;
}

.job-applications-details__title {
  color: #124F71;
  font-weight: 400;
  width: 100%;
  text-align: left;
  margin-bottom: 4px;
  font-size: 24px;
  cursor: pointer;
}

.job-applications-details__status {
  margin-bottom: 8px;
  font-weight: 400;
  color: $blue-grey;

  @include status-colors-parent();

  @include screen-size-xs {
    font-size: 16px;
  }

  @include screen-size-sm {
    font-size: 18px;
  }
}
