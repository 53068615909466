@import '~scss/mixins/screen-size';

@mixin generics-panel-form() {
  padding-top: 32px;
  padding-right: 16px;
  padding-bottom: 32px;
  padding-left: 16px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);

  @include screen-size-sm {
    padding-right: 64px;
    padding-left: 64px;
  }
}
