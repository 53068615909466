.input__switch {
  padding: 7px 0 5px;
  position: relative;

  label {
    width: 98px;
    height: auto;
    border: 1px solid #69D2F7;
    display: block;
    border-radius: 23px;
    cursor: pointer;
  }

  input {
    width: 0;
    height: 0;
    display: block;
    overflow: hidden;
    visibility: hidden;
  }

  input:checked + span:before {
    transform: translateX(68px);
    background: #37B1FF;
  }

  input:checked + span:after {
    content: 'Yes';
    color: #37B1FF;
    font-weight: 500;
    transform: translateX(-10px);
    animation-name: fadeIn;
  }

  input[disabled] + span {
    opacity: .5;
    cursor: not-allowed;
  }

  span {
    display: block;
    overflow: hidden;
    position: relative;
    font-size: 16px;
    color: #6B9FB8;

    &:before {
      content: '';
      top: 4px;
      border-radius: 100%;
      position: absolute;
      width: 22px;
      height: 22px;
      background: #A6C5D4;
      transform: translateX(6px);
      box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.10);
      transition: transform .3s ease-in-out, background .4s ease-in-out;
    }

    &:after {
      content: 'No';
      display: block;
      line-height: 30px;
      width: 100%;
      text-align: center;
      transform: translateX(10px);
      animation: fadeOut .3s ease-in-out;
    }
  }
}

@keyframes fadeIn {
  0%   { transform: translateX(10px);   opacity: 0; }
  30%  { transform: translateX(-100px); opacity: 1; }
  100% { transform: translateX(-10px);              }
}

@keyframes fadeOut {
  0%   { transform: translateX(-10px); opacity: 0; }
  30%  { transform: translateX(100px); opacity: 1; }
  100% { transform: translateX(10px);              }
}
