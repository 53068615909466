@import '~scss/components';

.profile__info__avatar {
  position: relative;

  @include screen-size-xs {
    width: 90px;
    min-width: 90px;
    height: 90px;
    margin-top: 24px;
    margin-right: 16px;
  }

  @include screen-size-sm {
    width: 138px;
    min-width: 138px;
    height: 138px;
    margin-right: 44px;
  }

  .card & {
    margin-top: 24px;
    margin-bottom: 48px;

    @include center-block-x();
  }
}
