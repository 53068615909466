.input__checkbox {
  user-select: none;
  height: 44px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &.is--small {
    height: 24px;

    & > label:before {
      width: 24px;
      height: 24px;
    }
  }

  & > label {
    color: #124F71;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: 1px solid #A6C5D4;
      border-radius: 4px;
    }
  }
  & > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  & > input:focus + label:before {
    border-color: #69D2F7;
  }
  & > input:checked + label:before {
    background: url('../../images/icon--checkbox.svg') 50% 50% no-repeat;
  }
}
