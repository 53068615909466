@import '~scss/components';

.job-details {
  display: flex;
  flex-direction: column;
  padding: 24px 0 0;
  position: relative;

  &.is--muted {
    height: 120px;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(#EAF4F7 30px, transparent 0),
      linear-gradient(#EAF4F7 20px, transparent 0),
      linear-gradient(#EAF4F7 20px, transparent 0);
    background-size:
      250px 30px,
      140px 20px,
      100px 20px;
    background-position:
      50% 0,
      50% 40px,
      50% 70px,
  }
}

.is--horizontal {
  padding: 0;
  justify-content: space-between;

  @include screen-size-xs() {
    flex-direction: column;
  }

  @include screen-size-sm() {
    flex-direction: row;
  }
}

.job-details__row {
  margin: 24px 0;
}

.job-details__wrapper {
  width: 100%;
  overflow: hidden;

  .is--horizontal & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.job-details__avatar {
  width: 106px;
  min-width: 106px;
  height: 106px;
  vertical-align: middle;

  @include screen-size-xs() {
    .is--horizontal & {
      width: 72px;
      min-width: 72px;
      height: 72px;
    }
  }
}

.job-details__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  width: 100%;

  .is--horizontal & {
    align-items: flex-start;

    @include screen-size-xs() {
      padding-right: 40px;
      padding-left: 24px;
    }

    @include screen-size-sm() {
      padding-right: 32px;
      padding-left: 32px;
    }
  }
}

.job-details__title {
  color: #124F71;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  text-indent: -9999;

  @include screen-size-xs() {
    width: 310px;
  }

  @include screen-size-sm() {
    width: 100%;
  }

  .is--horizontal & {
    text-align: left;
    margin-bottom: 4px;

    @include screen-size-xs() {
      font-size: 22px;
    }

    @include screen-size-sm() {
      font-size: 24px;
    }
  }
}

.job-details__company-name {
  font-size: 16px;
  color: #124F71;
  a {
    color: #124F71;
  }
  .is--horizontal & {
    margin-bottom: 3px;
  }
}

.job-details__category {
  color: #95A9B3;
  .is--horizontal & {
    margin-bottom: 3px;
  }
}

.job-details__address {
  .is--horizontal & {
    color: #95A9B3;
    margin-bottom: 3px;

    @include screen-size-xs() {
      font-size: 14px;
    }
  }
}

.job-details__info {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  @include screen-size-xs() {
    padding: 0;
  }

  .is--horizontal & {
    @include screen-size-xs() {
      flex-direction: row;
      margin-top: 24px;
    }

    @include screen-size-sm() {
      flex-direction: column;
      margin-top: 0;
    }
  }
}

.job-details__info__item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  min-width: 50%;

  @include screen-size-xs() {
    padding: 4px;
  }

  @include screen-size-sm() {
    padding-right: 8px;
    padding-left: 8px;
  }

  .is--horizontal & {
    padding: 0;

    @include screen-size-xs() {
      min-width: 50%;
      margin-bottom: 0;
    }

    @include screen-size-sm() {
      min-width: 120px;
      margin-bottom: 13px;
    }
  }

  // divider
  &:not(:last-child):after {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    border-left: 1px solid #DCE7EA;

    .is--horizontal & {
      @include screen-size-xs() {
        display: block;
      }

      @include screen-size-sm() {
        display: none;
      }
    }
  }
}

.job-details__info__label {
  font-size: 14px;
  color: #95A9B3;
  text-align: center;
}

.job-details__info__indicator {
  font-size: 24px;
  font-weight: 400;

  &.is--default { color: #6B9FB8 }

  &.is--highlighted-primary { color: #37B1FF; }

  &.is--highlighted-secondary { color: #14E3BB }
}
