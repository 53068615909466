@import '~scss/components';

.job__details {
  display: flex;
  flex-wrap: wrap;

  .card & {
    margin-top: -32px;
    margin-bottom: -56px;
  }
}

.job__details__content {
  min-height: 210px;

  @include screen-size-xs {
    width: 100%;
    padding: 24px 20px;
  }

  @include screen-size-sm() {
    width: 74%;
    padding: 28px 24px 24px 32px;
  }
}

.job__details__summary {
  border-left: 1px solid #EAF4F7;

  @include screen-size-xs() {
    display: flex;
    width: 100%;
  }

  @include screen-size-sm() {
    width: 26%;
    padding-bottom: 14px;
  }
}

.job__details__summary__item {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  font-size: 15px;
  font-weight: 200;
  text-align: left;
  color: #124F71;
  border-top: 1px solid #EAF4F7;

  @include screen-size-xs() {
    justify-content: center;
    width: 50%;
    padding-top: 24px;
    padding-bottom: 24px;

    & + .job__details__summary__item {
      border-left: 1px solid #eaf4f7;
    }
  }

  @include screen-size-sm() {
    width: 100%;
    padding-left: 16px;
  }

  .is--empty & { color: #DCE7EA }

  &:first-of-type {
    @include screen-size-sm() {
      border-top: none;
    }
  }

  strong {
    display: block;
    width: 80px;
    white-space: nowrap;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
    color: #0288D1;
    text-overflow: ellipsis;
    overflow: hidden;

    .is--empty & { color: #DCE7EA }
  }

  span {
    padding-left: 4px;
  }

  > .icon {
    color: #B1E5FC;
    width: 24px;
    text-align: center;
    font-size: 23px;
    margin-right: 7px;
    line-height: 28px;
  }
}

.job__details__map {
  flex: 2 100%;
  height: 93px;
  margin: 0;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
  background: #fff url('./placeholder--job-map.svg') 50% 50% no-repeat;
}

.job__details__title {
  color: #124F71;
  font-size: 20px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;

  .is--empty & { color: #A6C5D4 }
}

.job__details__positions {
  color: #6B9FB8;
  font-size: 15px;
  font-weight: 500;

  .is--empty & { color: #A6C5D4 }
}

.job__details__positions__amount {
  color: #FF900C;
  font-size: 18px;
  vertical-align: baseline;
  margin-left: 14px;
  padding-right: 30px;
  background: url('~images/icon--positions.svg') 100% 5px no-repeat;
}

.job__details__location {
  font-size: 14px;
  color: #6B9FB8;
  padding: 12px 0;

  & > .icon {
    font-size: 18px;
    vertical-align: sub;
    margin-right: 4px;
  }
}

.job__details__description {
  color: #324048;
  font-size: 15px;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;

  .is--empty & * { color: #6B9FB8 !important }
}
