.avatar {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  overflow: hidden;
  color: #fff;
  font-size: 50px;
  justify-content: center;
  align-items: center;

  &.has--border {
    border: 1px solid #D4DAD9;
  }

  &:before {
    content: '';
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255, .6);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    cursor: pointer;
  }

  & > .icon {
    vertical-align: middle;
  }

  &.is--editing:before  { opacity: 1 }
  &.is--uploaded:before { opacity: 1 }
}
