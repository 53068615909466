.challenge-questions-result {
  padding: 0;
}

.challenge-questions-result__item{
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.challenge-questions-result__question {
  font-size: 18px;
  color: #124F71;
  font-weight: 300;
  margin-bottom: 12px;
}

.challenge-questions-result__answer {
  font-size: 20px;
  color: #37B1FF;
  font-weight: 400;
  margin-left: 16px;

  .is--correct   & { color: #14E3BB }
  .is--incorrect & { color: #EF5350 }
}
