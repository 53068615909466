@import '~scss/components';

.form__section {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 24px;
  padding-bottom: 24px;

  @include screen-size-xs() {
    padding-right: 14px;
    padding-left: 14px;
  }

  @include screen-size-sm() {
    padding-right: 110px;
    padding-left: 110px;
  }

  &.is--secondary {
    background: #EAFDF9;
  }
}

.form__section__icon {
  font-size: 60px;
  position: absolute;
  left: 40px;
  color: #B1E5FC;

  @include screen-size-xs() {
    display: none;
  }

  .is--secondary & {
    color: #14E3BB;
  }
}
